<template>
  <!-- 默认进件页面(红色第一套) -->
  <div class="container u-flex-col" ref="container" v-cloak>
    <div class="top" ref="top">
      <!-- 顶部-->
      <div class="container-top u-flex u-row-center">
        <img src="../assets/images/icon_reg_tl3_1.png" />
        <span>我们非常重视您的信息安全，未经允许绝不对外展示！</span>
      </div>

      <!-- 用户资料 -->
      <div class="register-form">
        <!-- 顶部提示信息 -->
        <div class="div-info u-flex">
          <img src="../assets/images/icon_reg_tl3_2.png" />
          <div class="div-hint u-flex-col">
            <div class="div-hint_1 u-flex">
              <p class="text_hint_1">完成实名认证，领取个人</p>
              <p class="text_hint_2">专属额度</p>
            </div>
            <span class="text_hint_3"
              >*仅用于核验身份&nbsp;您的资料将被加密保护</span
            >
          </div>
        </div>

        <div class="line-division"></div>

        <!-- 真实姓名 -->
        <div class="user-info ui0" ref="realName">
          <van-cell-group>
            <van-field
              label="真实姓名"
              v-model.trim="form.realName"
              class="inp-elem"
              placeholder="请输入您的真实姓名(已加密)"
              @blur="handleBlur(0)"
              clearable
              input-align="right"
            />
          </van-cell-group>
        </div>
        <!-- 身份证号 -->
        <div class="user-info" ref="idCard">
          <van-cell-group>
            <van-field
              label="身份证号码"
              v-model.trim="form.idCard"
              maxlength="18"
              class="inp-elem"
              placeholder="请输入您的身份证号(已加密)"
              @blur="handleBlur(1)"
              clearable
              input-align="right"
            />
          </van-cell-group>
        </div>
      </div>

      <!-- 位置 -->
      <div class="register-form">
        <!-- 贷款申请城市 -->
        <div class="location">
          <van-cell-group>
            <van-field
              label="本人长期居住城市"
              readonly
              class="lct-inp"
              input-align="right"
              :border="false"
            >
              <template #input>
                <div class="lct-city u-flex u-row-center">
                  <img src="../assets/images/icon_reg_tl3_3.png" />
                  <span>{{ addressPositionName }}</span>
                </div>
              </template>
              <template #button>
                <div class="loc-update u-flex" @click="handleClickPosition">
                  <span>修改城市</span>
                  <img src="../assets/images/icon_right_red.png" />
                </div>
              </template>
            </van-field>
            <p class="lct-tips">
              金融机构不支持跨区城办理 请选择当前长期居住城市
            </p>
          </van-cell-group>
        </div>
      </div>

      <!-- 补充资产信息 -->
      <div v-if="this.basicInputNum >= 2" class="register-form rfa0">
        <div class="asset-info u-flex">
          <p class="text_hint_1">补充资产信息 平均提高</p>
          <p class="text_hint_2">90%通过率</p>
        </div>

        <div class="line-division"></div>

        <van-collapse v-model="activeNames">
          <!-- 芝麻分 -->
          <van-collapse-item
            class="custom-collapse-item"
            title="芝麻分"
            name="1"
            :value="this.assetFromJS.credit"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_4.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.credit"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseCreditCredit(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
          <!-- 房产 -->
          <van-collapse-item
            v-if="this.assetFromJS.credit"
            class="custom-collapse-item"
            title="房产"
            name="2"
            :value="this.assetFromJS.house"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_7.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.house"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseHouse(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
          <!-- 车产 -->
          <van-collapse-item
            v-if="this.assetFromJS.house"
            title="车产"
            name="3"
            :value="this.assetFromJS.car"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_8.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.car"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseCar(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
          <!-- 公积金 -->
          <van-collapse-item
            v-if="this.assetFromJS.car"
            title="公积金"
            name="4"
            :value="this.assetFromJS.accumulation"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_5.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.accumulation"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseAccumulation(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
          <!-- 社保 -->
          <van-collapse-item
            v-if="this.assetFromJS.accumulation"
            title="社保"
            name="5"
            :value="this.assetFromJS.social"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_6.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.social"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseSocial(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
          <!-- 商业保险 -->
          <van-collapse-item
            v-if="this.assetFromJS.social"
            title="商业保险"
            name="6"
            :value="this.assetFromJS.insurance"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_6.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.insurance"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseInsurance(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
          <!-- 职业 -->
          <van-collapse-item
            v-if="this.assetFromJS.social"
            title="职业"
            name="7"
            :value="this.assetFromJS.profession"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_9.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.profession"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseProfession(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
          <!-- 京东白条 -->
          <van-collapse-item
            v-if="this.assetFromJS.profession"
            title="京东白条"
            name="8"
            :value="this.assetFromJS.jdIous"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_10.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.jdIous"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseCreditJdIous(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
          <!-- 蚂蚁花呗 -->
          <van-collapse-item
            v-if="this.assetFromJS.jdIous"
            title="蚂蚁花呗"
            name="9"
            :value="this.assetFromJS.antCreditPay"
          >
            <template #icon>
              <img
                class="ld-left-icon"
                src="../assets/images/icon_reg_tl3_11.png"
              />
            </template>
            <template #right-icon>
              <div class="ld-right-div u-flex">
                <img
                  class="ld-right-icon"
                  src="../assets/images/icon_right_red.png"
                />
              </div>
            </template>
            <div class="tags">
              <van-row gutter="10">
                <van-col
                  class="u-flex u-row-center"
                  v-for="(item, index) in formItemData.antCreditPay"
                  :key="index"
                  :class="item.active ? 'active' : 'tags-item'"
                  span="8"
                  @click="handleChooseCreditAntCreditPay(index)"
                >
                  <div class="u-flex u-col-center u-row-center">
                    {{ item.dictLabel }}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>

    <!-- 马上申请 -->
    <div class="btn-con">
      <van-button
        class="btn"
        type="default"
        block
        :disabled="this.basicInputNum > 1 ? false : true"
        @click="handleSubmitTl3"
        :loading="loading"
        loading-type="spinner"
      >
        马上申请
      </van-button>
    </div>

    <!-- 定位城市下拉选 -->
    <van-popup
      v-model="showPickerCity"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        show-toolbar
        :columns="areaList"
        @change="onCityChange"
        @confirm="onAreaConfirm"
        @cancel="showPickerCity = false"
        value-key="localName"
      >
        <div slot="title" class="picker-title u-text-center">
          <p>请选择定位城市</p>
          <p class="pa">
            准确的贷款申请城市将决定我们是否能为您提供贷款服务，请按您所在地如实填写
          </p>
        </div>
      </van-picker>
    </van-popup>

    <!-- 退出阻断弹窗 -->
    <van-popup
      v-model="returnShow"
      :close-on-click-overlay="false"
      :style="{
        width: '80%',
        height: 'auto',
      }"
      round
    >
      <div class="return-con u-text-center">
        <h3>
          距离<span>{{ this.form.loanAmount }}</span
          >元额度
        </h3>
        <h1>仅剩1步</h1>
        <p class="return-con-tips">确定要离开吗？</p>
        <van-button
          class="return-con-btn"
          block
          color="#fb6b62"
          round
          @click="returnShow = false"
        >
          继续填写
        </van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { applyCommon } from "@/mixins/applyCommonTl3.js";
import { mapPosition } from "@/mixins/mapPosition.js";
import { applyBus } from "@/mixins/applyBusEntry.js";
import { ref } from "vue";
export default {
  name: "RegisterEntryTl3",
  mixins: [applyCommon, mapPosition, applyBus],
  data() {
    return {
      activeNames: ["1"],
      positionStatus: false, // 定位是否成功，默认false
      positionCityStatus: false, // 百度定位中是否包含城市信息(如果不包含也相当于定位失败)
      provinceArr: undefined, // 百度定位中是否包含城市信息(如果不包含也相当于定位失败)-集合中是对应省的一条数据
      address: {
        // 定位地址
        province: "", // 省
        city: "", // 市
      },
      areaList: [
        // 自定义省数据二级结构
        { values: [] },
        { values: [] },
      ],
      showPickerCity: false, // 省市联动弹窗状态
      assetFromJS: {
        // 资产提交表单
        credit: "", // 芝麻分
        house: "", // 房产
        car: "", // 车产
        accumulation: "", // 公积金
        social: "", // 社保
        insurance: "", // 商业保险
        profession: "", // 职业
        antCreditPay: "", // 蚂蚁花呗
        jdIous: "", // 京东分
      },
      form: {
        // 提交表单
        channelNo: this.$route.query.source, // 渠道标识
        loanAmount: this.$route.query.amount ? this.$route.query.amount : 0, // 借款金额
        loanPeriod:
          parseInt(this.$route.query.period) === 9
            ? 12
            : parseInt(this.$route.query.period), // 借款期限(宜享花传入的期限可能为9期，为了兼容咱们自己的借款期限(目前支持3 6 12 24)的情况如果宜享花传入的期数为9强制修改为12期 )
        loanPurpose: this.$route.query.purpose, // 借款用途
        realName: "", // 姓名
        idCard: "", // 身份证号码
        credit: "", // 芝麻分
        antCreditPay: "", // 蚂蚁花呗
        jdIous: "", // 京东分
        assets: [], // 个人资产
        gpsCityName: "", // 省市展示名称
        cityId: "", // 城市ID
        provinceId: "", // 省ID
      },
      incomingShow: false, // 提交进件信息弹窗
      returnShow: false, // 退出阻断弹窗状态
      tdArea: "", // 特定机构的地理位置信息
      isSpecificInstitution: false, //是否需要展示特定机构的协议
      companys: "", // 公司名称集合
      companyAlias: "", // 公司别名
      loaclTitle: "", // 局部协议弹窗顶部title,
      brand: this.$route.query.brand, // 设备型号
      downPageUrl: this.$route.query.downPageUrl, // 进件结果下载页地址
      applyId: "", // 进件申请ID
      applyTime: "", // 进件申请时间
      hasPushSuccess: undefined, // 是否推送成功
      jwt: this.$route.query.jwt, // 登录字符串
      env: process.env.NODE_ENV, // 是否是线上环境
      loading: false, //确认额度按钮点击状态
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程的字段1是 0否
      isRedirectWorkWeixinPage: parseInt(
        this.$route.query.isRedirectWorkWeixinPage
      ), // 标识如果不是贷超流程需要用此字段进行判断是否跳转引导企业微信页面中，引导用户用企业微信扫码联系客服1是 0否
      workWeixinUrl: this.$route.query.workWeixinUrl, // 跳转微信地址url
      redirectUrl: "", // 重定向链接
      productId: null, // 产品id
      sendData: null,
      basicInputNum: 0, // 基本信息输入
    };
  },
  mounted() {
    this.addHistory(); // 添加浏览器浏览记录
    this.checkPosition(); // 获取是否支持定位

    // vuex中存储登录状态
    this.$nextTick(() => {
      this.$store.dispatch("setJwt", this.jwt);
      this.handleLoadFinish(3);
    });
  },
  destroyed() {
    // 页面销毁时必须要移除这个事件，vue 不刷新页面，不移除会重复执行
    window.removeEventListener("popstate", this.openReturnPop, false);
  },
  watch: {
    //弹框监听，当弹框显示的时候，pushState 添加一个历史，供返回键使用
    returnShow(newVal) {
      if (newVal === true) {
        window.history.pushState(null, null, document.URL);
      }
    },
  },
  computed: {
    addressPositionName() {
      // 顶部定位名称
      if (this.positionStatus) {
        return `${this.address.city}`;
      } else {
        if (this.form.gpsCityName) {
          return this.form.gpsCityName;
        } else {
          return "定位失败";
        }
      }
    },
  },
  methods: {
    // 监听表单blur
    handleBlur(index) {
      switch (index) {
        case 0: // 姓名输入框失去光标
          if (this.form.realName === "") {
            this.basicInputNum = 0;
            this.$toast("请输入正确的姓名");
            return;
          }
          if (this.basicInputNum < 2) {
            this.basicInputNum = 1;
          }
          break;
        case 1: // 身份证输入框失去光标
          if (this.form.idCard !== "") {
            if (!this.regTest.regIdcard.test(this.form.idCard)) {
              this.basicInputNum = 0;
              this.$toast("请输入正确的身份证号码");
              return;
            }
            this.basicInputNum = 2;
          }
          break;
      }
    },
  },
  setup() {
    const activeNames = ref(["1"]);
    return { activeNames };
  },
};
</script>

<style lang="scss" scoped src="@/assets/styles/registerEntry_tl3.scss"></style>
